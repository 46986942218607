import { Button } from '@components/buttons';
import { useAsyncState } from 'client/lib/hooks';
import { rpx } from 'client/lib/rpx-client';
import { useMemo, useState } from 'preact/hooks';
import { HeaderNav } from './header-nav';
import type { CalendarEvent, View } from './types';
import type { Dayjs } from 'dayjs';
import type { DateRange } from 'shared/scheduling';
import dayjs from 'dayjs';
import { isNowish, summarizeTime } from '../dateutil';
import { ruzcal } from 'shared/urls';
import { ComponentChildren } from 'preact';
import { IcoCalendar } from '@components/icons';
import { Pill } from '@components/pill';

type Props = {
  gotoView(view: View, day?: Dayjs): void;
};

type MeetingType = 'upcoming' | 'past' | 'canceled';

function HeaderLink(props: { onClick(): void; children: ComponentChildren; isSelected?: boolean }) {
  return (
    <Button
      onClick={props.onClick}
      class={`p-1 px-3 hover:text-indigo-600 hover:bg-indigo-100 transition-all rounded-full ${
        props.isSelected ? 'bg-indigo-50 text-indigo-600' : 'text-inherit'
      }`}
    >
      {props.children}
    </Button>
  );
}

function BookingList(props: {
  events: CalendarEvent[];
  onCancel(eventId: string): void;
  onClickDay(day: Dayjs): void;
  onReschedule(eventId: string, range: DateRange): void;
}) {
  const now = dayjs();
  const today = now.format('YYYY-MM-DD');

  // Agenda-view
  //
  // 24 Feb, Thu    11am - 12pm    Meeting w/ Abe
  //                12:30 - 2pm    Chat w/ Halil
  // 25 Feb, Fri    All day        Slack off
  //
  // Group the events by day
  const groupedEvents = useMemo(
    () =>
      props.events.reduce<{
        next: string;
        live: string;
        days: Array<{
          day: Dayjs;
          events: typeof props.events;
        }>;
      }>(
        (acc, e) => {
          const prev = acc.days[acc.days.length - 1];
          if (!acc.live && isNowish(e)) {
            acc.live = e.id;
          } else if (!acc.next && now.isBefore(e.start)) {
            acc.next = e.id;
          }
          if (prev?.day.diff(e.start, 'day') === 0) {
            prev.events.push(e);
          } else {
            acc.days.push({ day: dayjs(e.start).startOf('day'), events: [e] });
          }
          return acc;
        },
        { days: [], next: '', live: '' },
      ),
    [props.events],
  );

  return (
    <div class="flex flex-col divide-y">
      {groupedEvents.days.map((d) => {
        const day = d.day.format('YYYY-MM-DD');
        const isToday = day === today;
        return (
          <section key={day} class="flex gap-2 p-4 items-start">
            <span class="w-32 flex items-center gap-2">
              <Button
                class={`font-medium text-lg rounded-full size-8 flex items-center justify-center ${
                  isToday ? 'bg-indigo-600 text-white ' : 'hover:bg-gray-200'
                }`}
                onClick={() => props.onClickDay(d.day)}
              >
                {d.day.format('D')}
              </Button>
              <span class={isToday ? 'text-indigo-600' : ''}>{d.day.format('MMM, ddd')}</span>
            </span>
            <div class="flex flex-col gap-4 md:gap-2 grow">
              {d.events.map((e) => {
                return (
                  <Button
                    key={e.id}
                    class="flex flex-col-reverse md:flex-row md:gap-2 text-left hover:bg-gray-100 rounded-lg p-1 px-2 text-inherit"
                    href={ruzcal.existingBookingUrl({
                      id: e.id,
                    })}
                  >
                    <span class="w-40">{summarizeTime(e)}</span>
                    <span class="font-semibold flex flex-wrap gap-2">
                      {!e.isCanceled && e.id === groupedEvents.next && (
                        <span>
                          <span
                            class={`text-white ${
                              e.id === groupedEvents.live
                                ? 'bg-green-500 animate-pulse'
                                : 'bg-indigo-500'
                            } inline-flex px-1 rounded-sm text-xs font-semibold`}
                          >
                            {e.id === groupedEvents.live ? 'Now' : 'Next'}
                          </span>
                        </span>
                      )}
                      {e.isCanceled && <Pill color="yellow">Canceled</Pill>}
                      {e.name} with {e.attendee.name}
                    </span>
                  </Button>
                );
              })}
            </div>
          </section>
        );
      })}
    </div>
  );
}

function NoBookings(props: { type: MeetingType }) {
  return (
    <div class="flex flex-col items-center text-center justify-center grow p-4 text-indigo-500 gap-10">
      <header class="flex items-center justify-center -ml-8">
        <IcoCalendar class="size-40 -rotate-12" />
      </header>
      <section class="flex flex-col items-center justify-center text-base font-medium">
        <h2 class="text-4xl font-semibold">No meetings</h2>
        <p>You have no {props.type} meetings.</p>
      </section>
    </div>
  );
}

export function AgendaView(props: Props) {
  const [type, setType] = useState<MeetingType>('upcoming');

  const loadAgenda = async (opts?: { cursor?: string }) => {
    const result = await rpx.ruzcalEvents.getMyAgenda({
      today: new Date(),
      type,
      cursor: opts?.cursor,
    });
    return {
      ...result,
      events: result.events.map((e) => ({
        ...e,
        start: new Date(e.start),
        end: new Date(e.end),
      })),
    };
  };

  const [agenda, setAgenda] = useAsyncState(() => loadAgenda(), [type]);

  return (
    <div class="flex flex-col gap-2 rounded-2xl border grow inset-0 overflow-hidden">
      <HeaderNav view="agenda" gotoView={props.gotoView}>
        <HeaderLink onClick={() => setType('upcoming')} isSelected={type === 'upcoming'}>
          Current <span class="hidden md:inline">& upcoming</span>
        </HeaderLink>
        <HeaderLink onClick={() => setType('past')} isSelected={type === 'past'}>
          Past <span class="hidden md:inline">meetings</span>
        </HeaderLink>
        <HeaderLink onClick={() => setType('canceled')} isSelected={type === 'canceled'}>
          Canceled <span class="hidden md:inline">meetings</span>
        </HeaderLink>
      </HeaderNav>
      {agenda?.events.length === 0 && <NoBookings type={type} />}
      {agenda && agenda.events.length > 0 && (
        <>
          <BookingList
            events={agenda.events}
            onClickDay={(day) => props.gotoView('day', day)}
            onReschedule={(eventId, range) => {
              setAgenda((s) => ({
                ...s!,
                events: s!.events.map((e) => (e.id === eventId ? { ...e, ...range } : e)),
              }));
            }}
            onCancel={(eventId) =>
              setAgenda((s) => ({
                ...s!,
                events: s!.events.filter((e) => e.id !== eventId),
              }))
            }
          />
          {agenda.hasMore && (
            <Button
              class="flex p-2 justify-center hover:bg-gray-100 rounded-lg"
              onClick={async () => {
                const result = await loadAgenda({ cursor: agenda.cursor });
                setAgenda((s) => ({
                  ...s,
                  ...result,
                  events: [...s!.events, ...result.events],
                }));
              }}
            >
              Load more
            </Button>
          )}
        </>
      )}
    </div>
  );
}
