import { UserProfileIcon } from '@components/avatars';
import {
  IcoArrowLeft,
  IcoArrowRight,
  IcoCalendar,
  IcoDocument,
  IcoDownload,
  IcoExternalLink,
  IcoMapPin,
  IcoVideoCamera,
} from '@components/icons';
import { RpxResponse, rpx } from 'client/lib/rpx-client';
import { useDocumentTitle } from 'client/utils/use-document-title';
import { BulletIco } from './bullet-ico';
import { ScheduleSummary } from './schedule-summary';
import { BtnBasicCopy, BtnPrimary, Button } from '@components/buttons';
import { useCurrentUser } from 'client/lib/auth';
import { isNowish } from './dateutil';
import { BaseDialog, Dialog, showDialog } from '@components/dialog';
import { showToast } from '@components/toaster';
import { ModalForm, showModalForm } from '@components/modal-form';
import { ScheduleBooking } from './schedule-picker';
import { useAsyncState, useTryAsyncData } from 'client/lib/hooks';
import { useMemo } from 'react';
import { EventRow } from 'server/types/cal-schema';
import { eventToIcal } from 'shared/scheduling/ical';
import { LoadedProps, RouteLoadProps } from '@components/router/async-router';
import { ruzcal } from 'shared/urls';
import { defCalRoute } from './common';
import dayjs from 'dayjs';
import { EventLocationInputs, EventLocationInputsState } from './cal-edit-event-types/basics-tab';
import { useState } from 'preact/hooks';
import { LoadingIndicator } from '@components/loading-indicator';
import { AutosizeText } from '@components/autosize-text';
import { Case } from '@components/conditional';

export const route = defCalRoute({ load, Page });

async function load(props: RouteLoadProps) {
  const result = await rpx.ruzcalEvents.getEvent({
    eventId: props.params.eventId,
  });
  return {
    ...result,
    isSuccess: !!props.params.success,
    event: {
      ...result.event,
      start: new Date(result.event.start),
      end: new Date(result.event.end),
    },
  };
}

type Props = LoadedProps<typeof load>;
type State = RpxResponse<typeof rpx.ruzcalEvents.getEvent> & {
  isSuccess?: boolean;
};

function jitsiUrl({
  eventType,
  event,
  attendeeName,
}: {
  eventType: State['eventType'];
  event: State['event'];
  attendeeName: string;
}) {
  const jitsiRoom = `${eventType.name}  Ruzuku Jitsi Conference  ${event.id}`.replaceAll(
    /[^a-zA-Z0-9-]/g,
    '-',
  );
  return `https://meet.jit.si/${encodeURIComponent(
    jitsiRoom,
  )}#userInfo.displayName=${encodeURIComponent(
    `"${attendeeName}"`,
  )}&config.subject=${encodeURIComponent(
    `"${eventType.name}"`,
  )}&config.prejoinConfig.enabled=false`;
}

function showLocationChangeForm({
  event,
  onSuccess,
}: {
  event: State['event'];
  onSuccess: (event: EventLocationInputsState) => void;
}) {
  showModalForm(({ resolve }) => {
    const [state, setState] = useState<EventLocationInputsState>({
      location: event.location,
      locationDetail: event.locationDetail,
    });
    const integrations = useTryAsyncData(async () => {
      return rpx.ruzcal.getCalIntegrations();
    }, []);

    if (!integrations.data) {
      return <LoadingIndicator />;
    }

    return (
      <ModalForm
        onClose={resolve}
        onSubmit={async () => {
          await rpx.ruzcalEvents.updateEventLocation({
            id: event.id,
            location: state.location,
            locationDetail: state.locationDetail,
          });
          onSuccess(state);
          showToast({
            type: 'ok',
            title: 'Location changed',
            message: 'The event location has been updated.',
          });
        }}
        title="Change event location"
        confirmButtonText="Change event location"
        isConfirmDisabled={state.location === 'zoom' && !integrations.data.hasZoom}
      >
        <EventLocationInputs
          state={state}
          onChange={setState}
          hasZoom={integrations.data.hasZoom}
          zoomOauthUrl={integrations.data.zoomOauth}
        />
      </ModalForm>
    );
  });
}

function RescheduleModal({
  close,
  state,
  onScheduleChange,
}: {
  state: State;
  onScheduleChange(event: EventRow): void;
  close(): void;
}) {
  const user = useCurrentUser();
  const { host, eventType, event } = state;
  const [data, setData] = useAsyncState(async () => {
    const result = await rpx.ruzcal.getInitialBookingState({
      urlPrefix: host.urlPrefix,
      urlSuffix: eventType.urlSuffix,
    });
    return {
      ...result,
      schedule: { start: event.start, end: event.end },
      hour12: true,
    };
  }, []);
  const attendeeTimeZone = useMemo(() => {
    return (
      user?.timezone ||
      Intl.DateTimeFormat().resolvedOptions().timeZone ||
      data?.availability.timezone ||
      'America/New_York'
    );
  }, [data?.availability.timezone, user?.timezone]);

  return (
    <BaseDialog onClose={close} contentWidth>
      {!data && <p>Loading...</p>}
      {data && (
        <section class="flex flex-col sm:gap-6 gap-10 sm:p-10 sm:w-2xl lg:w-4xl max-w-screen overflow-auto">
          <header>
            <h2 class="font-semibold text-base leading-4">Reschedule {eventType.name}</h2>
          </header>
          <div class="lg:grid lg:grid-cols-3 sm:gap-6 gap-10">
            <ScheduleBooking
              eventType={eventType}
              availability={data.availability}
              attendeeTimeZone={attendeeTimeZone}
              compact
              schedule={data.schedule}
              hour12={data.hour12}
              onScheduleChange={(schedule) => setData((s) => ({ ...s!, schedule }))}
              onHour12Change={(hour12) => setData((s) => ({ ...s!, hour12 }))}
              onClick={async (opts) => {
                const ok = await showDialog({
                  mode: 'info',
                  title: 'Reschedule your meeting?',
                  children: (
                    <>
                      Would you like to change your meeting to:
                      <ScheduleSummary schedule={opts.schedule} hour12={data.hour12} />
                    </>
                  ),
                  confirmButtonText: 'Yes. Reschedule.',
                  cancelButtonText: 'Keep the current schedule.',
                });
                if (!ok) {
                  return close();
                }

                const result = await rpx.ruzcalEvents.rescheduleEvent({
                  id: event.id,
                  start: opts.schedule.start,
                  end: opts.schedule.end,
                });
                onScheduleChange(result);
                showToast({
                  type: 'ok',
                  title: 'Meeting rescheduled',
                  message: 'Your changes have been saved.',
                });
                close();
              }}
            />
          </div>
        </section>
      )}
    </BaseDialog>
  );
}

function MeetingUser({
  user,
  isHost,
}: {
  user: { name: string; email?: string };
  isHost?: boolean;
}) {
  return (
    <li class="flex items-center gap-4">
      <UserProfileIcon user={user} size="size-10" />
      <span class="flex flex-col">
        <span>
          {user.name}
          {isHost && (
            <span class="bg-blue-500 text-white px-1 p-0.5 text-xs font-semibold rounded-sm ml-2">
              Host
            </span>
          )}
        </span>
        {user.email && (
          <a class="text-inherit" href={`mailto:${encodeURIComponent(user.email)}`}>
            {user.email}
          </a>
        )}
      </span>
    </li>
  );
}

/**
 * Matches URLs and emails and turns them into links.
 */
function Linkify(props: { text: string }) {
  const regex = /(https:\/\/[^\s]+)|(mailto:[^\s]+)/gi;
  const strs = props.text.split(regex);
  return (
    <>
      {strs.map((s, i) => {
        const isUrl = s?.startsWith('https://') || s?.startsWith('mailto:');
        return (
          <span key={i}>
            {isUrl && (
              <a href={s} target="_blank" rel="noreferrer" class="text-indigo-600 underline">
                {s}
              </a>
            )}
            {!isUrl && s}
          </span>
        );
      })}
    </>
  );
}

function CancelMeetingModal({
  eventId,
  isHost,
  onSuccess,
  onCancel,
}: {
  eventId: string;
  isHost: boolean;
  onSuccess(reason: string): void;
  onCancel(): void;
}) {
  const [reason, setReason] = useState('');

  return (
    <Dialog
      mode="warn"
      title="Are you sure you want to cancel this meeting?"
      confirmButtonText="Cancel the meeting"
      errorTitle="Failed to cancel the meeting"
      cancelButtonText="Keep the meeting"
      onSubmit={async () => {
        await rpx.ruzcalEvents.cancelEvent({
          id: eventId,
          reason,
        });
        showToast({
          title: 'Meeting canceled',
          message: 'The meeting has been canceled.',
          type: 'ok',
        });
        onSuccess(reason);
      }}
      onClose={onCancel}
    >
      <div class="mt-4">
        <label class="block mb-2">Reason for cancellation</label>
        <AutosizeText
          name="attendeeNotes"
          class="min-h-20 p-2 px-3 rounded-lg"
          maxLength={500}
          placeholder={`You can include an optional message for the ${
            isHost ? 'attendee' : 'host'
          }`}
          autoFocus
          value={reason}
          onInput={(e) => setReason(e.currentTarget.value)}
        />
      </div>
    </Dialog>
  );
}

function ActionsFooter({
  state,
  setState,
  isHost,
}: {
  state: Props['state'];
  setState: Props['setState'];
  isHost: boolean;
}) {
  return (
    <footer>
      You can{' '}
      <button
        type="button"
        class="text-indigo-600 underline"
        onClick={() => {
          showModalForm(({ resolve }) => (
            <RescheduleModal
              close={resolve}
              state={state}
              onScheduleChange={(event) => {
                const range = { start: new Date(event.start), end: new Date(event.end) };
                setState((s) => ({
                  ...s,
                  event: {
                    ...event,
                    ...range,
                  },
                }));
              }}
            />
          ));
        }}
      >
        reschedule
      </button>{' '}
      or{' '}
      <button
        type="button"
        class="text-indigo-600 underline"
        onClick={() =>
          showModalForm(({ resolve }) => (
            <CancelMeetingModal
              eventId={state.event.id}
              isHost={isHost}
              onCancel={resolve}
              onSuccess={(reason) => {
                setState((s) => ({
                  ...s,
                  event: { ...s.event, isCanceled: true, cancellationReason: reason },
                }));
                resolve();
              }}
            />
          ))
        }
      >
        cancel
      </button>{' '}
      any time before the meeting starts.
    </footer>
  );
}

function CanceledMeeting({ state, isHost }: { state: State; isHost: boolean }) {
  const { eventType, event, host, attendee } = state;

  return (
    <>
      <header>
        {isHost && (
          <a
            href={`/calendar/bookings?date=${encodeURIComponent(
              dayjs(event.start).format('YYYY-MM-DD'),
            )}`}
            class="flex items-center gap-2 mb-2"
          >
            <IcoArrowLeft /> All bookings
          </a>
        )}
        <h1 class="text-2xl font-semibold">This meeting is canceled</h1>
        <span class="text-base block mt-2">
          <span class="font-medium">Reason for cancellation:</span> {event.cancellationReason}
        </span>
      </header>
      <section class="flex flex-col border-t py-6 gap-8">
        <div class="flex flex-col gap-4">
          <h2 class="font-semibold text-lg text-gray-700">{eventType.name}</h2>
          <BulletIco Ico={IcoCalendar} multiline>
            <ScheduleSummary
              class={event.isCanceled ? 'line-through' : ''}
              schedule={event}
              hour12
            />
          </BulletIco>
          {event.notes && (
            <BulletIco Ico={IcoDocument} multiline>
              <span class="whitespace-pre-wrap">{event.notes}</span>
            </BulletIco>
          )}
        </div>
        <ul class="leading-snug flex flex-col gap-6 col-span-3">
          <MeetingUser user={host} isHost />
          <MeetingUser user={attendee} />
        </ul>
      </section>
    </>
  );
}

function Page(props: Props) {
  useDocumentTitle([props.state.event.isCanceled ? 'Meeting canceled' : 'Meeting scheduled!']);

  const { state, setState, auth } = props;
  const { user } = auth;
  const isHost = user?.id === state.host.userId;
  const { eventType, event, attendee, host } = state;

  const eventHref = ruzcal.existingBookingUrl({
    id: event.id,
    domain: location.origin,
  });
  const jitsiHref =
    event.location === 'jitsi'
      ? jitsiUrl({ eventType, event, attendeeName: user?.name ?? attendee.name })
      : undefined;
  const zoomHref =
    event.location === 'zoom' && event.zoomRoomId
      ? `https://zoom.us/j/${event.zoomRoomId}`
      : undefined;

  return (
    <div class="p-2 flex items-center justify-center bg-gray-100 min-h-screen an-scale-in">
      <section class="p-8 bg-white rounded-2xl max-w-full w-2xl flex flex-col gap-6">
        <Case
          when={!state.event.isCanceled}
          fallback={<CanceledMeeting state={state} isHost={isHost} />}
        >
          <header>
            {isHost && (
              <a
                href={`/calendar/bookings?date=${encodeURIComponent(
                  dayjs(event.start).format('YYYY-MM-DD'),
                )}`}
                class="flex items-center gap-2 mb-2"
              >
                <IcoArrowLeft /> All bookings
              </a>
            )}
            <h1 class="text-2xl font-semibold">
              {state.isSuccess ? 'Meeting scheduled!' : 'Meeting details'}
            </h1>
            {state.isSuccess && (
              <p class="text-gray-500">
                We sent a calendar invitation and meeting details to <em>{attendee.email}</em>.
              </p>
            )}
            {!state.isSuccess && eventType.description && (
              <p class="text-gray-500">{eventType.description}</p>
            )}
          </header>
          <section class="flex flex-col border-y py-6 gap-8">
            <div class="flex flex-col gap-4">
              <h2 class="font-semibold text-lg text-gray-700">{eventType.name}</h2>
              <BulletIco Ico={IcoCalendar} multiline>
                <ScheduleSummary schedule={event} hour12 />
              </BulletIco>
              {event.notes && (
                <BulletIco Ico={IcoDocument} multiline>
                  <span class="whitespace-pre-wrap">{event.notes}</span>
                </BulletIco>
              )}
              <div class="flex gap-2">
                {event.location === 'jitsi' && <BulletIco Ico={IcoVideoCamera}>Jitsi</BulletIco>}
                {zoomHref && (
                  <BulletIco Ico={IcoMapPin} multiline>
                    <a
                      class="flex items-center text-inherit hover:text-indigo-700 hover:underline gap-1"
                      href={zoomHref}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Zoom Video
                      <IcoExternalLink />
                    </a>
                  </BulletIco>
                )}
                {event.location === 'external' && event.locationDetail && (
                  <BulletIco Ico={IcoMapPin} multiline>
                    <span class="whitespace-pre-wrap">
                      <Linkify text={event.locationDetail.external} />
                    </span>
                  </BulletIco>
                )}
                {isHost && (
                  <button
                    class="text-indigo-600 underline"
                    onClick={() =>
                      showLocationChangeForm({
                        event,
                        onSuccess: (event) => {
                          setState((s) => ({
                            ...s,
                            event: {
                              ...s.event,
                              location: event.location,
                              locationDetail: event.locationDetail,
                            },
                          }));
                        },
                      })
                    }
                  >
                    Change location?
                  </button>
                )}
              </div>
              <Button
                class="hover:text-indigo-700 hover:underline"
                onClick={() => {
                  const attachment = eventToIcal({
                    attendee,
                    eventType,
                    host,
                    event,
                    eventURL: location.href,
                    isCanceled: false,
                  });
                  const url = URL.createObjectURL(attachment.data);
                  const a = document.createElement('a');
                  a.href = url;
                  a.download = attachment.filename;
                  a.click();
                  setTimeout(() => URL.revokeObjectURL(url), 1000);
                }}
              >
                <BulletIco Ico={IcoDownload}>Export to calendar</BulletIco>
              </Button>
            </div>
            <ul class="leading-snug flex flex-col gap-6 col-span-3">
              <MeetingUser user={host} isHost />
              <MeetingUser user={attendee} />
            </ul>
            <footer class="flex gap-4">
              {event.location === 'jitsi' && (isHost || isNowish(event)) && (
                <BtnPrimary class="rounded-full p-3 px-6 text-base" href={jitsiHref}>
                  <span class="flex items-center gap-2">
                    {isHost ? `Launch meeting` : `Join meeting`}
                    <IcoArrowRight />
                  </span>
                </BtnPrimary>
              )}
              {event.location === 'zoom' && (
                <BtnPrimary class="rounded-full p-3 px-6 text-base" href={zoomHref}>
                  {isHost ? `Launch meeting` : `Join meeting`}
                  <IcoArrowRight class="w-4 h-4 ml-2 " />
                </BtnPrimary>
              )}
              <BtnBasicCopy
                class="bg-gray-100 p-3 px-4 hover:bg-gray-200 rounded-full transition-all font-medium"
                value={eventHref}
                copiedText="Meeting link copied!"
              >
                Copy Meeting Link
              </BtnBasicCopy>
            </footer>
          </section>
          <ActionsFooter {...props} isHost={isHost} />
        </Case>
      </section>
    </div>
  );
}
