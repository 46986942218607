import { AsyncForm, Subtext } from '@components/async-form';
import { JSX, ComponentChildren } from 'preact';
import { useState } from 'preact/hooks';
import { AccessFormat, Course } from 'server/types';
import { showToast } from '@components/toaster';
import { IcoCalendar, IcoCollection } from '@components/icons';
import { useCurrentTenant } from '@components/router/session-context';
import { rpx } from 'client/lib/rpx-client';
import { Toggle } from '@components/toggle';
import { showModalForm } from '@components/modal-form';
import { DialogFooter, DialogHeader, StandardDialog } from '@components/dialog';
import { utcOffset } from 'shared/dateutil';

function AccessFormatRadio({
  title,
  desc,
  example,
  ...props
}: { title: string; desc: string; example?: string } & JSX.InputHTMLAttributes<HTMLInputElement>) {
  return (
    <label
      class={`p-4 pr-8 flex items-start rounded-md text-sm cursor-pointer ${
        props.checked ? 'ring-2 ring-indigo-600 text-gray-700' : ''
      }`}
    >
      <input
        type="radio"
        name="accessFormat"
        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 mr-4 mt-1"
        {...props}
      />
      <span class="flex flex-col space-y-2">
        <span class="font-bold">{title}</span>
        <Subtext>{desc}</Subtext>
        {!!example && <Subtext class="italic">Example: {example}</Subtext>}
      </span>
    </label>
  );
}

export function AccessFormatRadios(props: {
  accessFormat: AccessFormat;
  isAbsoluteSchedule: boolean;
}) {
  const { terminology } = useCurrentTenant();
  const [accessFormat, setAccessFormat] = useState<AccessFormat>(props.accessFormat);
  const [isAbsoluteSchedule, setIsAbsoluteSchedule] = useState(props.isAbsoluteSchedule);

  return (
    <>
      <div class="border rounded-2xl p-2">
        <span class="font-bold inline-flex items-center text-lg p-4">
          <IcoCollection class="w-5 h-5 opacity-75 mr-2" />
          Full Access Programs
        </span>
        <AccessFormatRadio
          title="Full Access"
          desc={`Students can view all ${terminology.course} ${terminology.modules} immediately upon signup.`}
          checked={accessFormat === 'openaccess'}
          value="openaccess"
          onClick={() => setAccessFormat('openaccess')}
        />
      </div>
      <div class="border rounded-2xl p-2">
        <span class="font-bold inline-flex items-center text-lg p-4">
          <IcoCalendar class="w-5 h-5 opacity-75 mr-2" />
          Drip-Released Programs
        </span>
        <AccessFormatRadio
          title="Calendar-Based Release Dates"
          desc={`Release your ${terminology.modules} on specific calendar dates for everyone to go through your ${terminology.course} together.`}
          checked={accessFormat === 'scheduled'}
          value="scheduled"
          example="July 2, 2022"
          onClick={() => setAccessFormat('scheduled')}
        />
        {accessFormat === 'scheduled' && (
          <label class="p-4 pl-12 pt-2 flex items-center gap-3 border-2 border-indigo-600 rounded-b-lg bg-white -mt-2 border-t-0 -mx-0.5 cursor-pointer">
            <input type="hidden" name="isAbsoluteSchedule" value={`${isAbsoluteSchedule}`} />
            <Toggle
              checked={!isAbsoluteSchedule}
              onClick={() => setIsAbsoluteSchedule((x) => !x)}
            />
            <span>
              Use the <em>student's</em> timezone to determine module and message schedules
            </span>
          </label>
        )}
        <AccessFormatRadio
          title="Individual Release Dates"
          desc={`Release your ${terminology.modules} over time, based on when an individual student signs up.`}
          example="7 days after a student registers"
          checked={accessFormat === 'ondemand'}
          value="ondemand"
          onClick={() => setAccessFormat('ondemand')}
        />
      </div>
    </>
  );
}

export function AccessFormatForm(props: {
  courseId: UUID;
  accessFormat: AccessFormat;
  horizontal?: boolean;
  isAbsoluteSchedule: boolean;
  onSuccess?: (fmt: AccessFormat) => void;
  children: ComponentChildren;
}) {
  const { terminology } = useCurrentTenant();
  return (
    <AsyncForm
      class="flex flex-col gap-6"
      onSubmit={async (data) => {
        await rpx.courses.setCourseAccessFormat({
          courseId: props.courseId,
          accessFormat: data.accessFormat,
          isAbsoluteSchedule: data.isAbsoluteSchedule === 'true',
          utcOffset: utcOffset(),
        });
        props.onSuccess?.(data.accessFormat);
        showToast({
          type: 'ok',
          title: `${terminology.module} scheduling changed`,
          message: 'Your changes have been saved.',
        });
      }}
    >
      <div class={`flex gap-6 ${props.horizontal ? 'flex-row' : 'flex-col'}`}>
        <AccessFormatRadios
          accessFormat={props.accessFormat}
          isAbsoluteSchedule={props.isAbsoluteSchedule}
        />
      </div>
      {props.children}
    </AsyncForm>
  );
}

function AccessFormatModal(props: {
  courseId: UUID;
  accessFormat: AccessFormat;
  isAbsoluteSchedule: boolean;
  onChange(fmt: AccessFormat): void;
  hide(): void;
}) {
  const { terminology } = useCurrentTenant();
  return (
    <StandardDialog onClose={props.hide}>
      <DialogHeader
        title={<>Set how your {terminology.course} is delivered</>}
        subtitle="Don't worry— you can change this at any time."
      />
      <AccessFormatForm
        courseId={props.courseId}
        accessFormat={props.accessFormat}
        isAbsoluteSchedule={props.isAbsoluteSchedule}
        onSuccess={async (fmt) => {
          props.onChange(fmt);
          props.hide();
        }}
      >
        <DialogFooter
          onClose={props.hide}
          confirmButtonText={<>Set {terminology.module} scheduling</>}
        />
      </AccessFormatForm>
    </StandardDialog>
  );
}

export function showAccessFormatModal(
  course: Pick<Course, 'id' | 'title' | 'isAbsoluteSchedule' | 'accessFormat'>,
) {
  showModalForm(({ resolve }) => (
    <AccessFormatModal
      courseId={course.id}
      accessFormat={course.accessFormat}
      isAbsoluteSchedule={!!course.isAbsoluteSchedule}
      hide={() => resolve(undefined)}
      onChange={() => location.reload()}
    />
  ));
}
